<template>
  <transition name="fade" @closesModal="accountInfoUpdateModal = false" @update="update = true">
    <accountInfoUpdate :detailsEmail="detailsEmail" :update="update" v-if="accountInfoUpdateModal" />
  </transition>

  <transition name="fade" @closesModal="accountInfoRegistModal = false">
    <accountInfoRegist v-if="accountInfoRegistModal" />
  </transition>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">사용자 관리</h1>
  </div>

  <!-- 검색 조건 -->
  <div class="container" v-if="isMobile == false">    
    <input v-model="searchText" class="form-control" type="text" placeholder="이름 / 이메일"  @keyup.enter="changePage(1, this.pagination.pageSize)" />	  
    <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>		
    <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('등록')" @mouseout="clearHoverText" :title="hoverText" @click="accountInfoRegistModal = true"></Button>        
  </div>

  <div class="container" v-if="isMobile == true">
    <div class="d-flex">
      <input v-model="searchText" class="form-control" type="text" placeholder="이름 / 이메일"  @keyup.enter="changePage(1, this.pagination.pageSize)" />	  
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>		
      <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('등록')" @mouseout="clearHoverText" :title="hoverText" @click="accountInfoRegistModal = true"></Button>    
    </div>
  </div>

  <!-- 본문 -->
	<div class="container" v-if="isMobile == false">	    
	  <table class="table table-bordered table-hover caption-top">
	    <thead class="table-light">
	      <tr class="table-primary">
          <th scope="col" v-for="item in header" :key="item">
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr v-for="(row, i) in member" :key="i">
          <td class="center" @click="accountInfoUpdateModal = true; detailsEmail = row.userEmail;">{{ row.name }}</td>
          <td class="center" @click="accountInfoUpdateModal = true; detailsEmail = row.userEmail;">{{ row.userEmail }}</td>
          <td class="center"><input type="checkbox" id="checkbox" :ref="'useYn-'+i" v-model="row.useYn" true-value="Y" false-value="N" @click="usech(row.userEmail, row.useYn, i)" /></td>
          <td class="center"><input type="checkbox" id="checkbox" :ref="'adminYn-'+i" v-model="row.adminYn" true-value="Y" false-value="N" @click="adminch(row.userEmail, row.adminYn, i)" /></td>
          <td class="center"><Button label="초기화" icon="pi pi-check" size="small" @click="passwordReset(row.userEmail)" outlined /></td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <!-- 본문 모바일 -->
  <div class="container" v-if="isMobile == true">
  	<Accordion :activeIndex="0">
	    <AccordionTab v-for="(row, i) in member" :key="i" :header="row.name + ' (' + row.userEmail + ') '">
	        <p>이름 : {{ row.name }}</p>
	        <p>이메일 : {{ row.userEmail }}</p>
	        <p>사용자여부 : <input type="checkbox" id="checkbox" :ref="'useYn-'+i" v-model="row.useYn" true-value="Y" false-value="N"
            @click="usech(row.userEmail, row.useYn, i)" /></p>
	        <p>관리자여부 : <input type="checkbox" id="checkbox" :ref="'adminYn-'+i" v-model="row.adminYn" true-value="Y" false-value="N"
            @click="adminch(row.userEmail, row.adminYn, i)" /></p>
	        <p><Button label="비밀번호초기화" icon="pi pi-check" size="small" @click="passwordReset(row.userEmail)" outlined /></p>
	        <button
		      class="btn btn-secondary"
		      @click="accountInfoUpdateModal = true; detailsEmail = row.userEmail;">
		      수정
		    </button>
	    </AccordionTab>
	  </Accordion>
  </div>
  
  <div class="container">
 	<Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
	<mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
   </div>
</template>

<script>
import accountInfoUpdate from './Update.vue'
import accountInfoRegist from './Regist.vue'
import Pagination from '@/components/Common/ClipPagination.vue'
import mPagination from '@/components/Common/mClipPagination.vue'

export default {
  name: 'ControlMember',
  data() {
    return {
      accountInfoUpdateModal: false,
      accountInfoRegistModal: false,
      member: {},
      header: ["이름", "이메일", "사용여부", "관리자", "비밀번호초기화"],
      detailsEmail: "",
      update: false,
      searchText: '',
      currentPage: 1,        // 현재 페이지
      itemsPerPage: 10,      // 페이지당 항목 수
      total: 0,      // 페이지당 항목 수
      isMobile : this.$isMobile(),
      pagination: {
    		endRow: 0,
		    hasNextPage: false,
		    hasPreviousPage: false,
		    isFirstPage: true,
		    isLastPage: true,
		    navigateFirstPage: 0,
		    navigateLastPage: 0,
		    navigatePages: 10,
		    navigatepageNums: [],
		    nextPage: 0,
		    pageNum: 1,
		    pageSize: 10,
		    pages: 0,
		    prePage: 0,
		    size: 0,
		    startRow: 0,
		    total: 0
    	},
      hoverText: '',
    };
  },
  components: {
    accountInfoUpdate,
    accountInfoRegist,
    Pagination,
    mPagination,
  },
  created() {
    var paramData = {
    	"pageNum": 1,
      "pageSize": this.isMobile ? 5 : 10,
      "userEmail": "",
      "name": ""
    };
    
    this.$get('/biz/account/list',  {
	            	params: paramData
	       		 },
                function(response, i){
                	i.member = response.data.result.list;   //기존데이터에 추가
                	i.pagination = response.data.result;
                },
                function(e, i){
                	console.log(e);
                }
     );
  }, watch: {
    update(update) {
      if (update == true) {
        window.location.reload(true);
      }
    }
  },
  computed: {
  },
  methods: {
    // 페이지 변경 시 호출될 함수
    changePage(newPage, pageSize) {
      this.currentPage = newPage;

      var paramData = {
        "pageNum": newPage,
        "pageSize": pageSize,
        "userEmail": this.searchText,
        "name": this.searchText
	    };
	    
    this.$get('/biz/account/list', {
	            	params: paramData
	       		 },
                function(response, i){
                	i.member = response.data.result.list;   //기존데이터에 추가
                	i.pagination = response.data.result;
                },
                function(e, i){
                	console.log(e);
                }
     );
     
    },
    usech(userEmail, useYn, i) {    
      var paramUseYn = this.$refs['useYn-'+i][0].checked? 'Y' : 'N';
            
    	var paramData = {
          "userEmail": userEmail,
          "useYn": paramUseYn
        };
      
		this.$post('/biz/account/mod/use-yn', paramData,
                function(response, i){
                	//console.log(response.status);
                },
                function(e, i){
                	console.log(e);
                }
      	);
    },
    adminch(userEmail, adminYn, i) {
  
    	var paramAdminYn = this.$refs['adminYn-'+i][0].checked? 'Y' : 'N';
    	    	 
    	var paramData = {
          "userEmail": userEmail,
          "adminYn": paramAdminYn
        };
      
		this.$post('/biz/account/mod/admin-yn', paramData,
                function(response, i){
                	//console.log(response.status);
                },
                function(e, i){
                	console.log(e);
                }
      	);
    },
    passwordReset(userEmail) {
      var paramData = {
        "userEmail": userEmail,
        "firstLoginYn": "Y",
        "reset": true
      };
      if (confirm("비밀번호를 초기화 하시겠습니까?")) {
      
      this.$post('/biz/account/mod/update-pw', paramData,
                function(response, i){
                 //console.log(response.status);
                 alert("비밀번호가 초기화되었습니다.")
                },
                function(e, i){
                   console.log(e);
                }
      	);
      }
    },
    setHoverText(text) {
      this.hoverText = text;
    },
    clearHoverText() {
      this.hoverText = '';
    },
  }
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
	.container {
	  display: block;
	}
	.selects {
	  margin-top: 10px;
	}
	.btn {
	  margin-top: 10px;
	}
}

th{
  text-align: center;
}
tr{
  cursor: pointer;
  vertical-align: middle;
}
.pi{
  min-width: 56px;
  width: 56px;
  min-height: 50px;
  height: 50px;
}
</style>