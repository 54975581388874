
<template>
  <transition name="fade" @closesModal="licenseDetailModal = false" @update="update = true">
    <LicenseOldDetail :detailsLicenseSeq="detailsLicenseSeq" :update="update" v-if="licenseDetailModal" />
  </transition>
  <transition name="fade" @closesModal="licenseIssueModal = false" @update="update = true">
    <LicenseIssue :detailsCompanySeq="detailsCompanySeq" :detailsProjectUid="detailsProjectUid" :detailsLicenseSeq="detailsLicenseSeq" :engineerRoleCheck="engineerRoleCheck" :update="update"  v-if="licenseIssueModal" />    
  </transition>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
    <h1 class="h2">(신/구) 라이선스 내역</h1>
  </div>

  <!-- 검색 조건 -->
  <div class="container" v-if="isMobile == false">  
    <input v-model="searchKeywordCompany" class="form-control" type="text" placeholder="고객사명" @keyup.enter="changePage(1, this.pagination.pageSize)" />  
    <input v-model="searchKeywordProject" class="form-control" type="text" placeholder="프로젝트명" @keyup.enter="changePage(1, this.pagination.pageSize)"  />  
    <Dropdown v-model="serverType" :options="serverTypes" optionLabel="serverName" placeholder="서버타입" class="w-full md:w-14rem" />  
    <input v-model="searchKeywordIP" class="form-control" type="text" placeholder="IP" @keyup.enter="changePage(1, this.pagination.pageSize)" />
    <input v-model="searchKeywordLicenseSerialNo" class="form-control" type="text" placeholder="일련번호" @keyup.enter="changePage(1, this.pagination.pageSize)" />    
    <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>		
    <Button class="pi pi-times-circle" severity="success" @click="unsetSearchData" @mouseover="setHoverText('검색 초기화')" @mouseout="clearHoverText" :title="hoverText"></Button>
    <Button @click="exportXls($event)" style="display:inline-flex; justify-content:center; background:#ffffff; border:1px solid #939599; height:8%; width:8%;">
      <img src="@/assets/excel.svg" alt="Excel Icon" style="width: 25px; height: 25px;" />
    </Button>
  </div>

  <!-- 검색 조건 모바일 -->
  <div class="container d-flex" v-if="isMobile == true" >
    <div class="d-flex" style="width:100%;">
	  <input v-model="searchKeyword" class="form-control" type="text" placeholder="프로젝트 / 고객사명 / IP / 일련번호"  @keyup.enter="changePage(1, this.pagination.pageSize)" />    
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>		
    </div>
  </div>

  <!-- 본문 -->
  <div class="container" v-if="isMobile == false">	    
	  <table class="table table-bordered table-hover caption-top">
	    <thead class="table-light">
	      <tr class="table-primary">
	        <th scope="col" v-for="item in header" :key="item" class="center">
	          {{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr v-for="(row, i) in license" :key="i">
          <td class="center"><Tag class="p-tag p-component p-tag-info" :style="tagStyle(row)">{{ row.corekey2 == '' ? 'Old' : 'New' }}</Tag></td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.companyName.length > 8 ? row.companyName.substring(0,8) + '...' : row.companyName }}</td>
          <td @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.projectName.length > 8 ? row.projectName.substring(0,8) + '...' : row.projectName }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.licenseSerialNo }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">
          {{ row.serverType == 'LOCAL' ? '로컬' :
            row.serverType == 'DEV' ? '개발' :
            row.serverType == 'TEST' ? '테스트' :
            row.serverType == 'STAGE' ? '검증' :
            row.serverType == 'PROD' ? '운영' : 
            row.serverType == 'DR' ? 'DR' : row.serverType
          }}</td>
          <!-- <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.startDate }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.endDate }}</td> -->
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.cpuInfo }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis; max-width: 280px;">{{ row.ipAddress.length > 45 ? row.ipAddress.substring(0,44) + '...' : row.ipAddress }}</td>        
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.r3 == 1 ? 'Y' : '' }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.r4 == 1 ? 'Y' : '' }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.r5 == 1 ? 'Y' : '' }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.e25 == 1 ? 'Y' : '' }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.e4 == 1 ? 'Y' : '' }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.e5 == 1 ? 'Y' : '' }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.registerName }}</td>              
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.modifierName }}</td>   
        </tr>
      </tbody>
    </table>
  </div> 

  <!-- 본문 모바일 -->
  <div class="container" v-if="isMobile == true">
  	<Accordion class="accordion-custom" :activeIndex="0">
	    <AccordionTab v-for="(row, i) in license" :key="i">        
	    	<template #header>
          <span>{{row.companyName + ' - ' + row.projectName}}
            <br/><span v-if="row.ipAddress !== null">(<span v-html="row.ipAddress !== null ? row.ipAddress.replaceAll(',', ',<br/>') : '-'"></span>)</span>
          </span>
        </template>
        <p>{{ header[2] }} : {{ row.licenseSerialNo }}</p>
        <p>{{ header[3] }} : {{ row.serverType == 'LOCAL' ? '로컬' :
            row.serverType == 'DEV' ? '개발' :
            row.serverType == 'TEST' ? '테스트' :
            row.serverType == 'STAGE' ? '검증' :
            row.serverType == 'PROD' ? '운영' : 
            row.serverType == 'DR' ? 'DR' : row.serverType
          }}</p>
        <!-- <p>{{ header[4] }} : {{ row.startDate }}</p>
        <p>{{ header[5] }} : {{ row.endDate }}</p> -->
        <p>{{ header[4] }} : {{ row.cpuInfo }}</p>
        <p>{{ header[5] }} : <span v-html="row.ipAddress !== null ? row.ipAddress.replaceAll(',', ',<br/>') : '-'"></span></p>
        <p>발급제품 : {{ row.r3 == 1 ? ' R3' : '' }}
          {{ row.r4 == 1 ? ' R4' : '' }}
          {{ row.r5 == 1 ? ' R5' : '' }}
          {{ row.e25 == 1 ? ' E25' : '' }}
          {{ row.e4 == 1 ? ' E4' : '' }}
          {{ row.e5 == 1 ? ' E5' : '' }}</p>	                  
        <p>{{ header[14] }} : {{ row.modifierName }}</p>
        <p>등록날짜 : {{ row.registerDate }}</p>
        <p style="overflow-wrap: break-word;">KEY : {{ row.key }}</p>
	    </AccordionTab>
	  </Accordion>
  </div>
  
  <div class="container">
    <LoadingBar :loading= "loading" v-if="loading == true"></LoadingBar>
	  <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
	  <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
  </div>
</template>

<script>
import LicenseOldDetail from '../LicenseOld/detail.vue'
import LicenseIssue from '../License/IssueNoRequest.vue'
import LoadingBar from '@/components/Common/LoadingBar.vue'
import Pagination from '@/components/Common/ClipPagination.vue'
import mPagination from '@/components/Common/mClipPagination.vue'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

export default {
  name: 'TrackingLicenseMain',
  data() {
    return {
      licenseDetailModal: false,
      licenseIssueModal: false,
      license: {},      
      header: ["신/구","고객사","프로젝트","인증번호","서버구분","CPU","IP","R3","R4","R5","E2.5","E4","E5","작성자","수정자"],
      detailsLicenseSeq: "",
      update: false,
      searchKeywordProject: '',
      searchKeywordCompany: '',
      searchKeywordIP: '',
      searchKeywordLicenseSerialNo: '',
      searchKeyword: '',
      serverType: {serverName:"ALL", value:""},
      serverTypes: [{serverName:"ALL", value:""},{serverName:"운영", value:"PROD"}, {serverName:"개발", value:"DEV"}, {serverName:"로컬", value:"LOCAL"}, {serverName:"테스트", value:"TEST"}, {serverName:"검증", value:"STAGE"}, {serverName:"DR", value:"DR"}],
      currentPage: 1,        // 현재 페이지
      itemsPerPage: 10,      // 페이지당 항목 수
      total: 0,      // 페이지당 항목 수
      pagination: {
    		endRow: 0,
		    hasNextPage: false,
		    hasPreviousPage: false,
		    isFirstPage: true,
		    isLastPage: true,
		    navigateFirstPage: 0,
		    navigateLastPage: 0,
		    navigatePages: 10,
		    navigatepageNums: [],
		    nextPage: 0,
		    pageNum: 1,
		    pageSize: this.isMobile ? 5 : 10,
		    pages: 0,
		    prePage: 0,
		    size: 0,
		    startRow: 0,
		    total: 0
    	},      
      hoverText: '',
      detailsProjectUid: '',
      detailsCompanySeq: '',
      loading: false,
    };
  },
  props: {
  	isMobile: Boolean,
  },
  components: {
    LicenseOldDetail,
    LicenseIssue,
    Pagination,
    mPagination,
    LoadingBar,
  },
  created() {      
  }, 
  watch: {
    update(update) {
      if (update == true) {
        //window.location.reload(true);
        var pageSize = this.isMobile ? 5 : 10;
        this.changePage(this.currentPage, pageSize);
        this.update = false;
      }
    }
  },
  computed: {
  },
  methods: {
    // 페이지 변경 시 호출될 함수
    changePage(newPage, pageSize) {
      if(this.searchKeywordProject == "" && this.searchKeywordCompany == "" && this.searchKeywordIP == "" && this.searchKeywordLicenseSerialNo == "" && this.serverType.serverName == "ALL") {
        this.$messageShow('검색어를 입력 해주세요.', 'warn');
      } else {      
        this.currentPage = newPage;
        this.loading = true;
        var paramData = {
          "pageNum": newPage,
          "pageSize": pageSize,
          "searchKeyword": this.searchKeyword,
          "searchKeywordProject": this.searchKeywordProject != undefined ? this.searchKeywordProject : "",
          "searchKeywordCompany": this.searchKeywordCompany != undefined ? this.searchKeywordCompany : "",
          "searchKeywordIP": this.searchKeywordIP,
          "searchKeywordLicenseSerialNo": this.searchKeywordLicenseSerialNo,
          "serverType": this.serverType.serverName != undefined ? this.serverType.value : "",
        };      
        this.$get('/biz/licenseold/listOldAndNew', {
                    params: paramData
                },
                    function(response, i){
                      i.license = response.data.pagination.list;   //기존데이터에 추가
                      i.pagination = response.data.pagination;
                      i.pagination.total == 0 ? i.$messageShow('조회된 값이 없습니다.', 'warn') : "";                  
                      i.loading = false;                     
                    },
                    function(e, i){
                      console.log(e);
                      i.loading = false;
                    }
        );
      }      
    },
    popupDetailModal(row){      
      if(row.corekey2 == "") {
        this.licenseDetailModal = true;
    	  this.detailsLicenseSeq = row.corekey;
      } else {
        this.licenseIssueModal = true;
        this.detailsProjectUid = row.corekey3;
        this.detailsCompanySeq = row.corekey;
        this.detailsLicenseSeq = row.corekey2;
      }
    },    
    unsetSearchData(){
    	this.searchKeyword = "";
	    this.searchKeywordProject = "";
	    this.searchKeywordCompany = "";
	    this.searchKeywordIP = "";
	    this.serverType = "";
	    this.searchKeywordLicenseSerialNo = "";
    },
    setHoverText(text) {
      this.hoverText = text;
    },
    clearHoverText() {
      this.hoverText = '';
    },
    exportXls() {
            var paramData = {
              "pageNum": 1,
              "pageSize": 1000000,
              "searchKeyword": this.searchKeyword,
              "searchKeywordProject": this.searchKeywordProject != undefined ? this.searchKeywordProject : "",
              "searchKeywordCompany": this.searchKeywordCompany != undefined ? this.searchKeywordCompany : "",
              "searchKeywordIP": this.searchKeywordIP,
              "searchKeywordLicenseSerialNo": this.searchKeywordLicenseSerialNo,
              "serverType": this.serverType.serverName != undefined ? this.serverType.value : "",
            };

            this.$get('/biz/licenseold/listOldAndNew', {
              params: paramData
            },
            (response) => {
              const data = response.data.pagination.list;
              const worksheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(data)));
              const workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

              const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
              const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

              saveAs(dataBlob, '(신/구) 라이선스 내역.xlsx');
            },
            (error) => {
              console.log(error);
            });
    },
    tagStyle(row){
      if(row.corekey2 == '') {
        return 'background-color: #f59e0b;';
      } else {
        return 'background-color: #198754;';
      }
    }
  },
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
	.container {
	  display: block;
	}
	.selects {
	  margin-top: 10px;
	}
	.btn {
	  margin-top: 10px;
	}
}

th{
  text-align: center;
}

.pi{
  min-width: 56px;
  width: 56px;
  min-height: 50px;
  height: 50px;
}
</style>