<template>
  <header class="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0" style="--bs-navbar-brand-padding-y: 0rem;">	

	<a href="/biz" class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 d-flex align-items-center" style="padding-top: 0; padding-bottom: 0;">		
		<img :class="{'mb-2': !isMobile, 'mb-3': isMobile}" src="@/assets/logo.png" alt="한컴이노보드" width="160" height="65">
		<b class="ms-2">한컴이노보드</b>
	</a>
	
	<div class="flex-shrink-0 dropdown">
		<a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">			
		<Tag v-if="isAuthenticated && isMobile == false" class="p-tag p-component p-tag-info" style="margin-right: 5px;vertical-align: text-bottom;">{{ isAuthenticated ? $store.getters.getUserEmail : "로그인" }}</Tag>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
			<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
			<path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
		</svg>
		</a>
		<ul class="dropdown-menu dropdown-menu-end text-small shadow">
		<li v-if="isAuthenticated">
			<router-link class="dropdown-item" :to="{ path: '/biz/UserInfo' }">사용자 정보 수정</router-link>
		</li>
		<li><a class="dropdown-item" @click="signoutBtn()">Sign out</a></li>
		</ul>
	</div>
    <button id="topMenuButton" class="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </header>
</template>
    
  <script>
  
  import { mapGetters } from "vuex"
  
    export default {
      name:'Main',
      data(){
        return {
			isMobile : this.$isMobile(),
			userName : '',
        }
      },
      props: {
        getUserEmail : String
      },
      computed: {
        ...mapGetters(["isAuthenticated", "getUserEmail"]),
      },
	//   mounted() { 		
	// 	var param = {
    // 	};

	// 	this.$post(`/biz/account/${this.$store.getters.getUserEmail}/get`, param,
	// 		function(response, i){
	// 			i.userName = response.data.name;
	// 		},
	// 		function(e, i){
	// 			console.log(e);
	// 		}
	// 	);
	//   },
      methods:{
    	signoutBtn(){
	    	this.$logout();
	    },
	    testBtn(){
	    	this.$code(["BUSINESS_TYPE"], false,
	    		function(result, i){
                   console.log(result);
                },
                function(e, i){
                	console.log(e);
                }
	    	);
	    }, 
    },
   }
  </script>
    
    <style scoped>
	    .bd-placeholder-img {
	        font-size: 1.125rem;
	        text-anchor: middle;
	        -webkit-user-select: none;
	        -moz-user-select: none;
	        user-select: none;
	      }
	
	      @media (min-width: 768px) {
	        .bd-placeholder-img-lg {
	          font-size: 3.5rem;
	        }
	      }
	
	      .b-example-divider {
	        height: 3rem;
	        background-color: rgba(0, 0, 0, .1);
	        border: solid rgba(0, 0, 0, .15);
	        border-width: 1px 0;
	        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
	      }
	
	      .b-example-vr {
	        flex-shrink: 0;
	        width: 1.5rem;
	        height: 100vh;
	      }
	
	      .bi {
	        vertical-align: -.125em;
	        fill: currentColor;
	      }
	
	      .nav-scroller {
	        position: relative;
	        z-index: 2;
	        height: 2.75rem;
	        overflow-y: hidden;
	      }
	
	      .nav-scroller .nav {
	        display: flex;
	        flex-wrap: nowrap;
	        padding-bottom: 1rem;
	        margin-top: -1px;
	        overflow-x: auto;
	        text-align: center;
	        white-space: nowrap;
	        -webkit-overflow-scrolling: touch;
	      }
		  .p-tag.p-tag-info {
			background: var(--blue-100);
			color: var(--blue-700);
		  }		  
		  .mb-2 {
			margin-bottom:0rem!important;
		  }

		  .mb-3 {
			margin-bottom:0rem!important;
			object-fit: cover;
			height: auto;
			width: 130px;
		  }
		  .navbar-brand {
			display: flex;
			align-items: center;
		}

		.navbar-brand img {
			flex-shrink: 0;
		}

		.navbar-brand b {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
        @import './headers.css';
    </style>