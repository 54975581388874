
<template>
  <main class="form-signin w-100 m-auto">      
      <div><img class="mb-2" src="@/assets/logo.png" alt="한컴이노보드" width="160" height="65">한컴이노보드</div>
      <h1 class="h3 mb-3 fw-normal">Please sign in</h1>	
      <div class="form-floating">
        <input type="email" class="form-control" ref="floatingInput" placeholder="name@example.com"  v-model="floatingInput">
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating">
        <input type="password" id="pw" class="form-control" ref="floatingPassword" placeholder="Password"  v-model="floatingPassword" @keyup.enter="signinBtn" style="margin-bottom: 5px;"> 
        <label for="floatingPassword">Password</label>
      </div>
      <div class="checkbox mb-3">
              <label><input type="checkbox" v-model="emailSaved"> 이메일 저장</label>
              <label><Button id = "pwBtn" :icon="pwBtnIcon" @click="pwView" style="margin-left:50px;width:25px;height:25px;color: #000000;background: #ffffff00;border: 1px solid #64748b;" /> 비밀번호 보기</label>
      </div>
      <div class="checkbox mb-3">
      <b style="color:red;font-size:14px;">{{errorMessage}}</b>
      </div>
      <button class="w-100 btn btn-lg btn-primary" @click="signinBtn">Sign in</button>
      <!-- <p class="mt-5 mb-3 text-muted">Copyright &copy; Hancominnostream<br/>All rights Reserved.</p> -->
	</main>
</template>

<script>
import { mapGetters } from "vuex"


export default {
  name: 'Login',
  props: {
    
  },
  computed: {
        ...mapGetters(["isAuthenticated"]),
  },
  data(){
        return {
          floatingInput : '',
          floatingPassword : '',
          errorMessage : '',
          emailSaved: false,
          pwBtnIcon:'pi pi-eye',
        }
  },
  created() {
    var saveEmailYn = this.$cookies.get('saveEmailYn');
    var userEmail = this.$cookies.get('userEmail');
    if (saveEmailYn === 'true' && userEmail) {
      this.emailSaved = true;
      this.floatingInput = userEmail;
    } else {
      this.emailSaved = false;
      this.floatingInput = '';
    }
    
  },
  methods:{
      signinBtn(){
        if(this.$emailValidation(this.floatingInput)){
      		this.errorMessage = '';
	        if(this.floatingPassword != ''){	        	                        
	        	this.$login(this.floatingInput, this.floatingPassword, this.emailSaved);	        	
	        }else{            
	          this.errorMessage = '입력하신 정보가 일치하지 않습니다.';
	          this.$refs.floatingPassword.focus();
	        }
	      }else{          
	        this.errorMessage = '메일주소가 형식에 맞지 않습니다.';
	        this.$refs.floatingInput.focus();
	      }
      },
      pwView(){
        var doc_pwd = document.getElementById("pw");                
        if(doc_pwd.type != "text") {
          doc_pwd.type = "text";
          this.pwBtnIcon = "pi pi-eye-slash";
        } else {
          doc_pwd.type = "password";
          this.pwBtnIcon = "pi pi-eye";
        } 
      },      
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .body{
    text-align:center!important;
  }
	.bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }

      .b-example-divider {
        height: 3rem;
        background-color: rgba(0, 0, 0, .1);
        border: solid rgba(0, 0, 0, .15);
        border-width: 1px 0;
        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
      }

      .b-example-vr {
        flex-shrink: 0;
        width: 1.5rem;
        height: 100vh;
      }

      .bi {
        vertical-align: -.125em;
        fill: currentColor;
      }

      .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
      }

      .nav-scroller .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
      .form-signin {
      max-width: 330px;
      padding: 15px;
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow-y: auto;
    }
      @import './signin.css';

</style>
