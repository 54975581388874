<template>
	<Dialog v-model:visible="visible" maximizable modal header="라이선스 정보 수정" :style="{ width: modalWidth }" @update:visible="$emit('update'); $emit('closesModal'); visible = false;">
		<div class="d-flex">							
				<Button label="히스토리" @click="visible2 = true, getLicenseHistory()" />			
		</div>
		<Dialog v-model:visible="visible2" modal header="히스토리" :style="{ width: modalWidth }" :breakpoints="{ '1199px': '50vw', '575px': '90vw' }">
			<Timeline :value="licenseHistoryList">
				<template #opposite="left" >						
					<small class="p-text-secondary">{{ left.item.regDate.substring(0,11) }}{{ left.item.regDate.substring(11,16) }}<br>{{ left.item.name }}</small>				
				</template>			
				<template #content="right">
					<template v-for="(field, index) in Object.keys(getNextLicenseName(historyHeader, right.item)).map((key) => key)" :key="index">
						<tr v-if="index >= 2 && getNextLicenseName(historyHeader, right.item)[field].split('|*|')[1] !== ''">
							<td nowrap><small class="p-text-secondary"><B>{{ getNextLicenseName(historyHeader, right.item)[field].split('|*|')[0] }}</B></small></td>
							<td nowrap style="max-width: 100px;"><small class="p-text-secondary" v-html="addNewLine(getNextLicenseName(historyHeader, right.item)[field].split('|*|')[1])"></small></td>
						</tr>
					</template>
				</template>
			</Timeline>
		</Dialog>
		  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
			  <Divider align="left" type="solid">
				<b>고객사</b>
			</Divider>
			<p>
				<AutoComplete v-model="company" optionLabel="companyName" dropdown :suggestions="filteredCompanys" @complete="companySearch" @change="setProjectList" style="width: 100%;"/>
			</p>
			
			<Divider align="left" type="solid">
				<b>프로젝트</b>
			</Divider>
			<p>
				<AutoComplete v-model="project" optionLabel="projectName" dropdown :suggestions="filteredProjects" @complete="projectSearch" @change="setProjectInfo"  style="width: 100%;"/>
			</p>
			
			<!-- <Divider align="left" type="solid">
				<b>도입년도</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="year" disabled />
			</p> -->
			
			<div v-if="partnerYn==='Y'">
				<Divider align="left" type="solid">
					<b>번들사</b>
				</Divider>
				<p>
					<input type="text" class="form-control" v-model="partnerName" disabled />
				</p>
			</div>
			
			<Divider align="left" type="solid">
				<b>영업대표</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="salesRepresentativeName" disabled />
			</p>
			
			<Divider align="left" type="solid">
				<b>발급제품</b>
			</Divider>
			<p>
				<MultiSelect v-model="issuedProd" display="chip" :style="{ 'max-width': '100%'} " :options="issuedProds" optionLabel="label" placeholder="제품 선택" filter  :maxSelectedLabels="5" class="w-full md:w-20rem" />
			</p>
	
			<Divider align="left" type="solid">
				<b>CPU</b>
			</Divider>
			<p>
				<InputNumber :min="0" v-model="cpuInfo" />
			</p>
			
			<Divider align="left" type="solid">
				<b>IP 타입</b>
			</Divider>
			<p>
				<span v-for="(ip) in ipTypes" :key="ip" style="margin-right: 8px;">
					<input type="radio" v-model="ipType" :value="ip.code" :id="ip.code" @change="unsetIpData"/>
					<label :for="ip.code">{{ip.label}}</label>
				</span>
			</p>
			
			<Divider align="left" type="solid" v-if="ipType !== 'ALL'">
				<b>IP</b> 
			</Divider>
			<template v-if="ipType === 'MULTI'">
				<p v-for="row in ipRows" :key="row" style="display: flex; align-items: center; flex-wrap: nowrap;">
					<InputNumber v-model="row.ipAClass" :min="0" :max="255" inputStyle="width: 3.5rem" />.
					<InputNumber v-model="row.ipBClass" :min="0" :max="255" inputStyle="width: 3.5rem" />.
					<InputNumber v-model="row.ipCClass" :min="0" :max="255" inputStyle="width: 3.5rem" />.
					<InputNumber v-model="row.ipDClass" :min="0" :max="255" inputStyle="width: 3.5rem; margin-right: 8px;"/>
					<Button label="삭제" icon="pi pi-minus" outlined @click="removeRow(row)" />
				</p>
				<p><Button label="IP추가" icon="pi pi-plus" severity="secondary" rounded @click="addRow(0)" />					
				<Button label="IP복붙" icon="pi pi-copy" severity="info" rounded @click="copyAndPaste" v-tooltip.top="{
						value: '복사 예시) (+개행) \n\n192.168.0.100\n192.168.0.200\n192.168.0.300'
					}" style="margin-left:5px"/></p>
			</template>
			<template v-if="ipType === 'BANDWIDTH'">
				<p v-for="row in ipRows" :key="row">
					<InputNumber v-model="row.ipAClass" :min="0" :max="255" inputStyle="width: 3.5rem" />.
					<InputNumber v-model="row.ipBClass" :min="0" :max="255" inputStyle="width: 3.5rem" />.
					<InputText maxlength="3" v-model="row.ipCClass" style="width: 56px" @keyup="checkIP(row.ipCClass)"/>.
					<InputText type="text" v-model="row.ipDClass" value="*" disabled style="width: 56px; margin-right: 8px;"/>
					<Button label="삭제" icon="pi pi-minus" outlined @click="removeRow(row)" />
				</p>
				<p><Button label="IP추가" icon="pi pi-plus" severity="secondary" rounded @click="addRow('*')" /></p>
				
			</template>
			<Divider align="left" type="solid">
				<b>서버타입</b>
			</Divider>
			<p>
				<Dropdown v-model="serverType" editable :options="serverTypes" optionLabel="label" placeholder="서버타입을 선택해 주세요." class="w-full md:w-14rem" />
			</p>
			
			<Divider align="left" type="solid">
				<b>클라우드</b>
			</Divider>
			<p>
				<Checkbox v-model="cloudYn" value="Y"/>
			</p>
			
			<Divider align="left" type="solid">
				<b>기간</b>
			</Divider>
			<p>
				<span v-for="(period) in periodTypes" :key="period" style="margin-right: 8px;">
					<input type="radio" v-model="periodType" :value="period.code" :id="period.code"  @change="periodTypeCheck"/>
					<label :for="period.code">{{period.label}}</label>
				</span>
			</p>
			
			<div v-if="this.periodType === 'TIME_LIMIT'">
				<p>			    					
					<SelectButton v-model="period369Val" :options="period369Arr" aria-labelledby="basic" @change="changeEndDate369"/>
				</p>
				<Divider align="left" type="solid">
					<b>효력일</b>
				</Divider>
				<p>
					<Calendar v-model="startDate" dateFormat="yy/mm/dd" showIcon showButtonBar />
				</p>
				
				<Divider align="left" type="solid">
					<b>만료일</b>
				</Divider>
				<p>
					<Calendar v-model="endDate" dateFormat="yy/mm/dd" showIcon showButtonBar />
				</p>
			</div>

			<div v-if="isDecode">				
				<Divider align="left" type="solid">
					<b>효력일</b>
				</Divider>
				<p>
					<Calendar v-model="decodedLicense.sdate" showIcon showButtonBar disabled />
				</p>
			</div>
			
			<Divider align="left" type="solid">
					<b>신/구</b>
			</Divider>
			<p>
				<span v-for="(version,index) in licenseVersions" :key="version" style="margin-right: 8px; ">
					<input type="radio" v-model="licenseVersion" :value="version.code" :id="version.code" :style="{ 'display': index === 0 ? 'inline-block' : 'none' }" />
					<label :for="version.code" :style="{ 'display': index === 0 ? 'inline-block' : 'none' }">{{version.label}}</label>
				</span>
			</p>
			<Divider align="left" type="solid">
					<b>key</b>
			</Divider>
			<p>
				<Textarea id = "keyTextArea" v-model="issuedLicense" autoResize rows="4" disabled :style="{ 'font-family': 'Consolas,monaco,monospace' ,'min-width': '100%'  }"/>
			</p>		
		  </div>
		  <template #footer>
			<Button label="닫기" id="licenseIssueCloseButton" icon="pi pi-times" @click="$emit('update'); $emit('closesModal'); visible = false;" text />
			<Button label="발급" icon="pi pi-check" autofocus data-bs-toggle="modal" v-if="engineerRoleCheck == true" data-bs-target="#updateConfirmMoral"/>
			<div class="flex align-items-center gap-2" style="margin: 15px 10px 0px 0px;">               
				<span class="p-text-secondary"><b>{{ modifierName == "" ? registerName : modifierName }}</b></span><br>          
				<span class="p-text-secondary"><div class="text-xs flex align-items-center text-700"><i class="pi pi-calendar mr-1 text-xs"></i> {{ modDate == null ? regDate : modDate }}</div></span>
			</div>    
		</template>
		<div class="modal fade" id="updateConfirmMoral" tabindex="-1" aria-labelledby="updateConfirmMoralLabel" aria-hidden="true">
		  <div class="modal-dialog">
			<div class="modal-content">
			  <div class="modal-body">
				  <div class="mb-3">
					<label for="message-text" class="col-form-label">발급 내용</label>
					<textarea class="form-control" v-model="licenseComment" id="message-text" placeholder="라이선스 발급관련정보를 입력해 주세요." ></textarea>
				  </div>
			  </div>
			  <div class="modal-footer" v-if="processing == false">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="licenseIssuedCloseButton">닫기</button>
				<button type="button" class="btn btn-primary" @click="update" :data-bs-dismiss="modal">발급</button>
			  </div>
			  <div class="modal-footer" v-if="processing == true">
				<button class="btn btn-primary" type="button" disabled>
				  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
				  Please Wait...
				</button>
			  </div>
			</div>
		  </div>
		</div>
	  </Dialog>
	</template>
	
	<script>
	import _ from "lodash";
	export default {
	  name: 'LicenseInfoUpdateModal',
	  data(){
		return{
		  companys: [],
		  filteredCompanys: [],   
		  filteredIssuedProds: [],
		  issuedProds: [],
		  projects: [],
		  filteredProjects: [],
		  filteredRequestDeptEmails: [],
		  ipTypes: [],
		  serverType: "",
		  serverTypes: {},
		  periodTypes: {},
		  licenseVersions: {},
		  company: "",
		  project: "",
		  projectSerialNo: "",
		  year: "",
		  issuedProd: "",
		  partnerName: "",
		  issueReqInfoYn: 'Y',
		  issueReqInfo: "",
		  cpuInfo: "",
		  ipType: "MULTI",
		  ipRows: [],
		  cloudYn: "",
		  startDate: "",
      	  endDate: "",
		  periodType: "TIME_LIMIT",
		  licenseVersion: "V3",
		  visible: true,
		  visible2: false,
		  modalWidth: this.$isMobile() ? '100vw' : '35vw',
		  innerModalvisible: false,
		  licenseComment: "",
		  issuedLicense: "",
		  etc: "",
		  issueStatusType: "",
		  partnerYn: "",
		  licenseSerialNo: "",
		  processing: false,
		  requestInfo : "",
		  modifierName: "",
		  modDate: "",
		  registerName: "",
		  regDate: "",
		  licenseHistoryList: [],
		  historyHeader: ["수정일","수정자","수정내용", "발급상태", "고객사", "프로젝트","제품", "CPU", "IP", "서버타입", "클라우드", "시작일", "종료일", "Key"],
		  details: {},
		  period369Arr:['3개월','6개월','12개월'],
		  period369Val:'',
		  decodedLicense: {},
		  isDecode: false,
		}
	  },
	  props: {
		  detailsCompanySeq: Number,
		  detailsProjectUid: String,
		  detailsLicenseSeq: Number,
		  engineerRoleCheck: Boolean,
	  },
	  components: {
	  },
	  created(){		
		var param = {
		};
		this.$code(["PROD_CODE"], false,
			function(result, i){				
				i.issuedProds = result.prodCode;
			},
			function(e, i){
				console.log(e);
			}
		);
	  },
	  mounted(){
			  this.$nextTick(function() {
				  this.setLicenseInfo();
			});			
			//달력(속성 변경)
			this.$calendar();			
	  },
	  methods: {
		setLicenseInfo(){			
			this.$get(`/biz/license/get`, {
							params: {
									companySeq : this.detailsCompanySeq,
									projectUid : this.detailsProjectUid,
									licenseSeq : this.detailsLicenseSeq,
								}
							},
						function(response, i){
							var detail = response.data.detail;
							i.details = detail;
							i.modifierName = detail.modifierName;
							i.modDate = detail.modDate;
							i.registerName = detail.registerName;
							i.regDate = detail.regDate;	
							
							i.$get('/biz/company/list',  {
											params: {}
											},
										function(response, i){		
	
											   i.companys = response.data.result;   //기존데이터에 추가
											   i.companys.forEach(function(company, index) {
												if(detail.companySeq === company.companySeq){
													i.company = company;
												}
											});
										},
										function(e, i){
											console.log(e);
										}
							 );
										 
	
							i.$code(["IP_TYPE", "SERVER_TYPE", "PERIOD_TYPE", "LICENSE_VERSION", "PROD_CODE"], false,
								function(result, i){
			
								   i.ipTypes = result.ipType;
								   i.serverTypes = result.serverType;
								   i.periodTypes = result.periodType;
								   i.licenseVersions = result.licenseVersion;
								   i.issuedProds = result.prodCode.filter(item => (item.codeSort < 10 && item.codeSort >= 1) || item.codeSort >= 1000);								   
								   
								   i.serverTypes.forEach(function(serverType, index) {
										if(detail.serverType === serverType.value){
									   i.serverType = serverType;
										 }
									});
									
									if(detail.issueStatusType != "ISSUED"){
										i.licenseComment =  "최초 발급";
										
										var deliveryProds = [];
										 
									   result.prodCode.forEach(function(deliveryProd, index) {
											detail.deliveryProdCode.split(",").forEach(function(prodCode, index) {
												if(prodCode === deliveryProd.value){
													deliveryProds.push(deliveryProd);
												}
											});
											
										});
										
										i.issuedProd = deliveryProds;
										 
										
									}else{
										 if(detail.issuedProdCode != null && detail.issuedProdCode.length > 0){
											var issuedProds = [];
											   
											i.issuedProds.forEach(function(issuedProd, index) {
												detail.issuedProdCode.split(",").forEach(function(prodCode, index) {
													if(prodCode === issuedProd.value){
														issuedProds.push(issuedProd);
													}
												});
												
											});
											
											i.issuedProd = issuedProds;
										
										}
									}
																	
								   
								   
								},
								function(e, i){
									console.log(e);
								}
							);
	 
							i.$get('/biz/project/list',  {
											params: {
													companySeq : detail.companySeq,
												}
								 },
									function(response, i){
										
										const current = new Date();
										i.projects = response.data.result;   //기존데이터에 추가
										i.projects.forEach(function(project, index) {
											if(detail.companySeq === project.companySeq && detail.projectUid === project.projectUid){
												i.project = project;
												i.partnerName = project.partnerName;
												i.salesRepresentativeName = project.salesRepresentativeName;
												i.year = project.year !== "" ? project.year : current.getFullYear();
												i.licenseSerialNo = project.projectSerialNo;
											
											}
										});
									},
									function(e, i){
										console.log(e);
									}
							);
	
							i.issueReqInfoYn = detail.issueReqInfoYn;
							i.issueReqInfo = detail.issueReqInfo;
							i.cpuInfo = detail.cpuInfo;
							i.ipType = detail.ipType;
							i.requestInfo = detail.requestInfo;
							
							
							if(detail.ipType != 'ALL'){
								var ips = detail.ipAddress.split(',');
	
								ips.forEach(function(ip, index) {
									var tempIp = ip.split('.');
									i.ipRows.push({ipAClass: tempIp[0], ipBClass: tempIp[1], ipCClass: tempIp[2], ipDClass: tempIp[3]});
								   
								});
							}
							
							if(detail.cloudYn != null && detail.cloudYn != ""){
								i.cloudYn = [detail.cloudYn];
							} else {
								i.cloudYn = null;
							}
											  
							i.periodType = detail.periodType;
							
							if(detail.licenseVersion != null){
								i.licenseVersion = detail.licenseVersion;
							}
							
							i.issuedLicense = detail.issuedLicense;
							
							i.etc = detail.etc;			      
							
							i.issueStatusType = detail.issueStatusType;			
							
							if(detail.periodType != "TIME_LIMIT"){
								i.startDate = "";
								i.endDate = "";								
								
								var param = {
									encodedLicenseKey : i.issuedLicense,
									licenseVersion : i.licenseVersion,
								};	    
								i.$get('/biz/license/decode',  {
											params: param
									},
									function(response, i){
										i.decodedLicense = response.data.decodeResult;   //기존데이터에 추가										
										i.isDecode = true;
									},
									function(e, i){
										console.log(e);
									}
								);
							}else{
								i.startDate = new Date(detail.startDate);
								i.endDate = new Date(detail.endDate);
							}
						},
						function(e, i){
							console.log(e);
						}
		  );
		  
		},
		  periodTypeCheck(){
			  if(this.periodType != "TIME_LIMIT"){
				  this.startDate = "";
				  this.endDate = "";
			  }else{
				  this.startDate = new Date();
			  }
		},
		changeEndDate369(){											
			var N_MonthsLater = new Date(this.startDate);			
			N_MonthsLater.setMonth(N_MonthsLater.getMonth() + Number(this.period369Val.split('개월')[0]));
			this.endDate = new Date(N_MonthsLater);
		},
		  update() {
			var checked = false;
			var ipAddresses = [];
			var issuedProdCodes = [];
			
			if(this.issuedProd != null && this.issuedProd != 'undefined' && this.issuedProd != ''){
	
					  this.issuedProd = _.uniqBy(this.issuedProd, 'value');
				
					this.issuedProd.forEach(function(prod, index) {
					  issuedProdCodes.push(prod.value);
					});
					
					if(issuedProdCodes.length < 1){
						  this.$messageShow('도입제품은 한개이상 필수선택항목입니다.', 'warn');
						  return checked;
					  }
					
				  }else{
					  this.$messageShow('도입제품은 한개이상 필수선택항목입니다.', 'warn');
					  return checked;
				  }
			
			if(this.cpuInfo == null || this.cpuInfo == "" || this.cpuInfo == 0) {
			  this.$messageShow('CPU 정보는 필수입력항목입니다.', 'warn');
			  return checked;
			}
			
			if(this.ipType != 'ALL'){
	
				if(this.ipRows.length < 1){
					  this.$messageShow('IP 정보는 한개 이상 입력이 필요합니다.', 'warn');
					  return checked;
				}else{
					this.ipRows.forEach(function(ip, index) {
					  
					  if(ip.ipAClass == null || ip.ipAClass == ""){
						  ip.ipAClass = 0;
					  }
					  if(ip.ipBClass == null || ip.ipBClass == ""){
						  ip.ipBClass = 0;
					  }
					  if(ip.ipCClass == null || ip.ipCClass == ""){
						  ip.ipCClass = 0;
					  }
					  if(ip.ipDClass == null || ip.ipDClass == ""){
						  ip.ipDClass = 0;
					  }
					  ipAddresses.push(ip.ipAClass + '.' + ip.ipBClass + '.' + ip.ipCClass + '.' + ip.ipDClass);
					});
				}
			
			}
			
			if(this.serverType == "") {
			  this.$messageShow('서버타입은 필수입력항목입니다.', 'warn');
			  return checked;
			}
			
			if(this.periodType == "TIME_LIMIT"){
				if(this.startDate == "" || this.endDate == "" || this.startDate == null || this.endDate == null){
					  this.$messageShow('효력일, 만료일 입력이 필요합니다.', 'warn');
					return checked;
				}else{
						if(this.startDate > this.endDate){
							this.$messageShow('만료일이 효력일보다 빠른날짜 입니다. 기간 확인이 필요합니다.', 'warn');
							return checked;
						}
				}
			}
					
			if(this.licenseComment == ""){
				this.$messageShow('발급 내용 입력이 필요합니다.', 'warn');
				return checked;
			}else{
				checked = true;	
			}
	
			const dissuedProdCode_s = this.details.issuedProdCode != null ? this.details.issuedProdCode.split(',').sort() : "";
			const issuedProdCodes_s = issuedProdCodes.sort();			
			const dipAddress_s = this.details.ipAddress != null ? this.details.ipAddress.split(',').sort() : "";
			const ipAddresses_s = ipAddresses != null && ipAddresses.length != 0 ? ipAddresses.sort() : "";
					
			if (this.details.companyName == this.company.companyName
			&& this.details.projectName == this.project.projectName			
			&& JSON.stringify(dissuedProdCode_s) == JSON.stringify(issuedProdCodes_s)
			&& this.details.cpuInfo == this.cpuInfo
			&& this.details.ipType == this.ipType
			&& JSON.stringify(dipAddress_s) == JSON.stringify(ipAddresses_s)
			&& this.details.serverType ==  this.serverType.value
			&& (this.details.cloudYn == "" ||this.details.cloudYn == null || this.details.cloudYn ? '' : this.details.cloudYn)  == (this.cloudYn == "" ||this.cloudYn == null || this.cloudYn === undefined ? '' : this.cloudYn[0])
			&& this.details.startDate == (this.periodType == "TIME_LIMIT" ? this.$simpleDateTimeFormat(this.startDate, "yyyy/MM/dd") : "")
			&& this.details.endDate == (this.periodType == "TIME_LIMIT" ? this.$simpleDateTimeFormat(this.endDate, "yyyy/MM/dd") : "")) {
						checked = false;
						this.$messageShow('기존 정보와 같습니다.', 'warn');
						return checked;
			}
	
			if(checked){
				if(confirm("라이선스 발급을 진행하시겠습니까?")){
					this.processing = true;
					var param = {
					  "licenseSeq" : this.detailsLicenseSeq,
					  "companySeq": this.company.companySeq ,
					  "companyName": this.company.companyName ,
					  "projectUid": this.project.projectUid ,
					  "projectName": this.project.projectName ,
					  "issueReqInfoYn" : 'Y',
					  "cpuInfo": this.cpuInfo,
					  "ipType": this.ipType,
					  "ipFrom" : ipAddresses,
					  "serverType": this.serverType.value,
					  "cloudYn": this.cloudYn != null ? this.cloudYn[0] : "",		          
					  "periodType": this.periodType,
					  "startDate" : this.periodType == "TIME_LIMIT" ? this.$simpleDateTimeFormat(this.startDate, "yyyy/MM/dd") : "",
					  "endDate" : this.periodType == "TIME_LIMIT" ? this.$simpleDateTimeFormat(this.endDate, "yyyy/MM/dd") : "",
					  "issueReqInfo" : this.issueReqInfo,
					  "registerEmail" : this.$store.getters.getUserEmail,
					  "modifierEmail" : this.$store.getters.getUserEmail,
					  "issueStatusType" : "ISSUED",
					  "etc" : this.etc,
					  "licenseComment" : this.licenseComment,
					  "licenseVersion" : this.licenseVersion,
					  "issuedProdCodes" : issuedProdCodes,
					  "licenseSerialNo" : this.licenseSerialNo,
					  "requestInfo" : this.requestInfo,
					};
					
					this.$post('/biz/license/issue', param,
								function(response, i){
									i.processing = false;
									 var detail = response.data.detail;
									i.issuedLicense = detail.issuedLicense;
									i.$messageShow('라이선스 발급이 완료되었습니다.', 'success');
									
									setTimeout(() => {
										document.getElementById("licenseIssuedCloseButton").click();
										document.getElementById("keyTextArea").scrollIntoView({ behavior: "smooth" });
									}, 100);
								},
								function(e, i){
									i.processing = false;
									i.errorMessage = e;
								}
					 );  
				}
			
			}
		
		},
		setProjectList() {			
			this.partnerName = "";
			this.salesRepresentativeName = "";
			this.project = "";
			
			var param = {
				companySeq : this.company.companySeq,
			};
		
			this.$get('/biz/project/list',  {
							params: param
				 },
					function(response, i){
						i.projects = response.data.result;   //기존데이터에 추가
					},
					function(e, i){
						console.log(e);
					}
			);
				
		},
		companySearch(event) {
				 setTimeout(() => {
					if (!event.query.trim().length) {
						this.filteredCompanys = this.companys.filter((company) => company.useYn === 'Y');
					} else {
						this.filteredCompanys = this.companys.filter((company) => {
							return company.useYn === 'Y' && company.companyName.toLowerCase().includes(event.query.toLowerCase());
						});
					}
				}, 250);
				
				
		},
		projectSearch(event) {
				 setTimeout(() => {
					if (!event.query.trim().length) {
						this.filteredProjects = this.projects.filter((project) => project.useYn === 'Y');
					} else {
						this.filteredProjects = this.projects.filter((project) => {
							return project.useYn === 'Y' &&  project.projectName.toLowerCase().includes(event.query.toLowerCase());
						});
					}
				}, 250);
				
		},
		setProjectInfo(){
			var param = {
				companySeq : this.project.companySeq,
				projectUid : this.project.projectUid
			};
		  
			  this.$get(`/biz/project/get`, {
							params: param
							},
							function(response, i){								
								i.issuedProd = null;
								i.partnerName = response.data.detail.partnerName;
								i.salesRepresentativeName = response.data.detail.salesRepresentativeName;
								i.partnerYn = response.data.detail.partnerYn;
								
							},
							function(e, i){
								console.log(e);
							}
			 );
		
		},    
		addRow: function(ipDClass){
		  this.ipRows.push({ipAClass: 0, ipBClass: 0, ipCClass: 0, ipDClass: ipDClass});		  
		},
		removeRow: function(row){
		  this.ipRows.splice(this.ipRows.indexOf(row), 1);
		},
		checkIP: function(ipCClass){
			var regExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])|\*)$/i;			
			if(!regExp.test(ipCClass)){
				this.$messageShow("IP 주소는 클래스별 0부터 255사이의 숫자 또는 '*'문자를 이용하여 입력을 해야 합니다.", 'warn');
				return false;
			}
		},
		checkIP2: function(ipCClass){
			var regExp = /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i;			
			if(!regExp.test(ipCClass)){
				this.$messageShow("IP 주소는 클래스별 0부터 255사이의 숫자를 이용하여 입력을 해야 합니다.", 'warn');
				return false;
			}
		},
		unsetIpData: function(){
			if(this.ipType == 'ALL'){
				this.ipRows = [];
			}else{
				if(this.ipType == 'MULTI'){
					this.ipRows = [{ipAClass: 0, ipBClass: 0, ipCClass: 0, ipDClass: 0}];
				}else{
					this.ipRows = [{ipAClass: 0, ipBClass: 0, ipCClass: 0, ipDClass: '*'}];
				}
			}
		},
		issuedProdSearch(event) {
				 setTimeout(() => {
					if (!event.query.trim().length) {
						this.filteredIssuedProds = [...this.issuedProds];
					} else {
						this.filteredIssuedProds = this.issuedProds.filter((issuedProd) => {
							return issuedProd.label.toLowerCase().includes(event.query.toLowerCase());
						});
					}
				}, 250);
				
				
		},
		getLicenseHistory() {    		
			var param = {
				licenseSeq : this.detailsLicenseSeq,
			};	
			this.$get('/biz/license/getH',  {			
							params: param
					},
					function(response, i){
						i.licenseHistoryList = response.data.detailHistory;
					},
					function(e, i){
						console.log(e);
					}
			);	
				
		},
		getNextLicenseName(header, item) {     
			const currentIndex = this.licenseHistoryList.indexOf(item);
			
			var properties = Object.keys(item).map((key) => key);
			var rtValue = {};
			
			if (currentIndex < this.licenseHistoryList.length - 1) {		
				for (let i = 0; i < header.length; i++) {
					let property = properties[i];			
					rtValue[property] = (this.licenseHistoryList[currentIndex][property] == this.licenseHistoryList[currentIndex + 1][property] ? "" : header[i] + '|*|' + (this.licenseHistoryList[currentIndex][property] != null ? this.licenseHistoryList[currentIndex][property] : ""));
				}
			} else {			
				for (let i = 0; i < header.length; i++) {
				let property = properties[i];			
				rtValue[property] = header[i] + '|*|' + (this.licenseHistoryList[currentIndex][property] != null ? this.licenseHistoryList[currentIndex][property] : "");
				}
			}
			return rtValue;
		},	
		addNewLine(value) {						
			if(value != undefined && value != null) {				
				if(value.indexOf('.') == -1 && value.indexOf('allip') == -1)
					return value;
				else
					return value.replace(/,/g, '<br>');
					
			}						
		},
		copyAndPaste(){						
			if(navigator.clipboard == null){
				this.$messageShow("https 에서만 지원 합니다.", 'warn');
			} else {			
				var ipRows_prev = this.ipRows;
				this.ipRows = [];	
				
				setTimeout(() => {
						navigator.clipboard.readText().then(text => {
						const lines = text.split('\r\n');
						for (let i = 0; i < lines.length; i++) {
							const ipAddressParts = lines[i].split('.');

							var regExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i;
							if(!regExp.test(lines[i])) {
								this.$messageShow(i+1+ "번째 IP 주소가 잘 못 되었습니다.", 'warn');
								this.ipRows = ipRows_prev;
								break;
							} else {
								if (ipAddressParts.length === 4 && (this.checkIP2(ipAddressParts[0]) != false && this.checkIP2(ipAddressParts[1])) != false && this.checkIP2(ipAddressParts[2]) != false && this.checkIP2(ipAddressParts[3]) != false) {							
								this.addRow(i);
								const newRow = this.ipRows[i];
								newRow.ipAClass = parseInt(ipAddressParts[0]);
								newRow.ipBClass = parseInt(ipAddressParts[1]);
								newRow.ipCClass = parseInt(ipAddressParts[2]);
								newRow.ipDClass = parseInt(ipAddressParts[3]);
								} else {
									this.ipRows = ipRows_prev;
								}
							}						
						}
					}).catch(err => {
						console.error('Failed to read clipboard contents: ', err);
					});
				}, 300);
			}	
		}	
	  }
	}
	</script>
	
	<style scoped>
	.black-bg {
	  width: 100%; 
	  height: 100%;
	  background: rgba(0,0,0,0.5);
	  position: fixed;
	  padding: 20px;
	}
	.white-bg {
	  width: 400px;
	  background: white;
	  border-radius: 8px;
	  padding: 20px;
	  margin: 0 auto;
	  transform: translate(-50%, 5%);
	}
	h4 {
	  padding-bottom: 10px;
	  margin-bottom: 20px;
	  border-bottom: 1px solid rgb(169, 168, 168);
	}
	.check {
	  margin-left: 40px;
	}
	</style>
	
	