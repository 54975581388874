<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">라이선스 복호화</h1>
  </div>    
  <SelectButton v-model="selectedOption" :options="options" optionDisabled="constant" optionLabel="name" aria-labelledby="basic" />    
  
  <div class="flex-container">     
    <span class="p-float-label">
    <InputText v-if="isMobile==false" id="username" v-model="encodedLicenseKey" style="width: 300px;" />
    <Textarea v-if="isMobile==true" id="username" v-model="encodedLicenseKey" autoResize rows="4" :style="{ 'font-family': 'Consolas,monaco,monospace' ,'min-width': '300px'  }"/>
    <label for="username">License Key</label>
    </span>    
    <Button @click="runDecode" icon="pi pi-arrow-right-arrow-left" style="color: white;background-color: #3B82F6; margin-left: 5px;margin-right: 5px;" />
  </div>
  <br><br>
  <table>
  	<tr>
        <th scope="row">고객사</th>
        <td>{{ decodedLicense.companyName }}</td>
    </tr>
    <tr>
        <th scope="row">프로젝트</th>
        <td>{{ decodedLicense.projectName }}</td>
    </tr>
    <tr>
        <th scope="row">인증번호</th>
        <td>{{ decodedLicense.licenseSeq }}</td>
    </tr>
	  <tr>
        <th scope="row">ip</th>
        <td>{{ decodedLicense.ip }}</td>
    </tr>
    <tr>
        <th scope="row">cpu core</th>
        <td>{{ decodedLicense.cpu }}</td>
    </tr>    
    <tr>
        <th scope="row">시작일</th>
        <td>{{ decodedLicense.sdate }}</td>
    </tr>
    <tr>
        <th scope="row">만료일</th>
        <td>{{ decodedLicense.edate }}</td>
    </tr>        
    <tr>
        <th scope="row">모듈버젼</th>
        <td>{{ decodedLicense.products }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'LicenseMain',
  data() {
    return {      
      isMobile : this.$isMobile(),
      encodedLicenseKey : '',
      decodedLicense: {},      
      selectedOption: { name: 'V3', value: 'V3', constant: false },    
      options: [
                { name: 'V3', value: 'V3', constant: false },
                { name: 'V4', value: 'V4', constant: true }
            ],
    };
  },  
  components: {    
  },
  created() {  	
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize',this.onResize,{passive:true});
  },
  beforeDestory(){
    if(typeof window === 'undefined') return;
    window.removeEventListener('resize',this.onResize,{passive:true});
  },
  methods: {
  	onResize(){
      this.isMobile = window.innerWidth < 600      
    },
    runDecode(){            
      if(this.encodedLicenseKey != undefined){
      const encodedLicenseKey = this.encodedLicenseKey.replace(/ /g, '');      
        var param = {
          encodedLicenseKey : encodedLicenseKey,
          licenseVersion : this.selectedOption.value,
			};	    
        this.$get('/biz/license/decode',  {
                      params: param
        },
        function(response, i){
          i.decodedLicense = response.data.decodeResult;   //기존데이터에 추가
        },
        function(e, i){
          console.log(e);
        }
			);
		  } 

    },
  },
  
};
</script>

<style scoped>
/* 추가적인 스타일링이 필요하다면 여기에 추가하세요. */
.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 12px;  
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  width:100px;
  text-align: center;
}

/* 헤더를 위로 이동시키기 위한 스타일 */
thead th {
  height: 0;
  line-height: 0;
}

tbody td:first-child, thead th:first-child {
  padding-left: 0;
}

tbody td, thead th {
  border-left: 1px solid #ddd;
}
.flex-container {
  display: flex;
  align-items: center; /* 요소들을 세로 중앙에 정렬합니다. */
  margin-Top: 30px;
}
</style>
